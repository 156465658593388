var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:['form__container', _vm.size ? 'form__container--' + _vm.size : ' ' , 
                {'column--align-left':(_vm.align === 'left')},
                {'column--align-middle':(_vm.align === 'middle')},
                {'column--align-right':(_vm.align === 'right')},
                {'column--align-top':(_vm.align === 'top')},
                {'column--align-bottom':(_vm.align === 'bottom')},

                {'column--align-top-left':(_vm.align === 'top left')},
                {'column--align-top-middle':(_vm.align === 'top middle')},
                {'column--align-top-right':(_vm.align === 'top right')},

                {'column--align-middle-left':(_vm.align === 'middle left')},
                {'column--align-middle-right':(_vm.align === 'middle right')},

                {'column--align-bottom-left':(_vm.align === 'bottom left')},
                {'column--align-bottom-middle':(_vm.align === 'bottom middle')},
                {'column--align-bottom-right':(_vm.align === 'bottom right')}]},[_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }