<template>
  <div :class="['form__container', size ? 'form__container--' + size : ' ' , 
                {'column--align-left':(align === 'left')},
                {'column--align-middle':(align === 'middle')},
                {'column--align-right':(align === 'right')},
                {'column--align-top':(align === 'top')},
                {'column--align-bottom':(align === 'bottom')},

                {'column--align-top-left':(align === 'top left')},
                {'column--align-top-middle':(align === 'top middle')},
                {'column--align-top-right':(align === 'top right')},

                {'column--align-middle-left':(align === 'middle left')},
                {'column--align-middle-right':(align === 'middle right')},

                {'column--align-bottom-left':(align === 'bottom left')},
                {'column--align-bottom-middle':(align === 'bottom middle')},
                {'column--align-bottom-right':(align === 'bottom right')}]">
		<slot></slot>
	</div>
</template>

<script>
export default {
  props: {
    size: String,
    align: String,
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/breakpoints.scss';

.form__container{
	@include breakpoint(sm){
		grid-column-start:auto;
		grid-column-end: span 12;
		max-width:100%;
	}
}
.form__container--12{
  grid-column-end: span 12;
}
.form__container--11{
  grid-column-end: span 12;
	@include breakpoint(sm){
		grid-column-end: span 11;
	}
}
.form__container--10{
  grid-column-end: span 12;
	@include breakpoint(sm){
		grid-column-end: span 10;
	}
}
.form__container--9{
  grid-column-end: span 12;
	@include breakpoint(sm){
		grid-column-end: span 9;
	}
}
.form__container--8{
  grid-column-end: span 12;
	@include breakpoint(sm){
		grid-column-end: span 8;
	}
}
.form__container--7{
  grid-column-end: span 12;
	@include breakpoint(sm){
		grid-column-end: span 7;
	}
}
.form__container--6{
  grid-column-end: span 12;
	@include breakpoint(sm){
		grid-column-end: span 6;
	}
}
.form__container--5{
  grid-column-end: span 12;
	@include breakpoint(sm){
		grid-column-end: span 5;
	}
}
.form__container--4{
  grid-column-end: span 12;
	@include breakpoint(sm){
		grid-column-end: span 4;
	}
}
.form__container--3{
  grid-column-end: span 12;
	@include breakpoint(sm){
		grid-column-end: span 3;
	}
}
.form__container--2{
  grid-column-end: span 12;
	@include breakpoint(sm){
		grid-column-end: span 2;
	}
}
.form__container--1{
  grid-column-end: span 12;
	@include breakpoint(sm){
		grid-column-end: span 1;
	}
}
.form--col-auto .form__container{
  grid-column-end: span 12;
	@include breakpoint(sm){
		grid-column-end: auto;
	}
}

.column--align-left{
  align-self: start;
  justify-self: start;
}
.column--align-middle{
  align-self: center;
  justify-self: center;
}
.column--align-right{
  align-self: start;
  justify-self: end;
}
.column--align-top{
  align-self: start;
  justify-self: start;
}
.column--align-bottom{
  align-self: end;
  justify-self: start;
}
.column--align-top-left{
  align-self: start;
  justify-self: start;
}
.column--align-top-middle{
  align-self: start;
  justify-self: center;
}
.column--align-top-right{
  align-self: start;
  justify-self: end;
}
.column--align-middle-left{
  align-self: center;
  justify-self: start;
}
.column--align-middle-right{
  align-self: center;
  justify-self: end;
}
.column--align-bottom-left{
  align-self: end;
  justify-self: start;
}
.column--align-bottom-middle{
  align-self: end;
  justify-self: center;
}
.column--align-bottom-right{
  align-self: end;
  justify-self: end;
}
</style>

<!-- Used in : AR -->